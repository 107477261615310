@use "@angular/material" as mat;

@import "theme/theme.scss";
@import "theme/variables.scss";
@import "theme/mixins.scss";
@import 'swiper/css/bundle';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  strong,
  b {
    font-weight: 700;
    font-family: 'Montserrat-Bold';
  }
}

$applicationPrefix: "sound";
$namespace: "sound";

/** COLORS **/

/** PRIMARY **/
$primary-purple-50: #8533a6;
$primary-red: #ff0f8f;
$primary-red-50: #ff0f8f;
$primary-green-50: #33ffa2;
$primary-dark-purple: #322e33;
$primary-no-black: #2a262b;
$primary-artwork-bg: #343434;
$primary-white: #ffffff;
$primary-blue: #26BAFE;

/** SECONDARY **/
$secondary-green-logo: #1fcc76;
$secondary-red-60: #c90e71;
$secondary-green-60: #2ee693;
$secondary-green-70: #24b372;
$secondary-orange: #ff6f59;
$secondary-orange-70: #b24e3e;
$secondary-yellow: #d7ff73;
$secondary-purple-70: #5c2473;
$secondary-purple-60: #712b8c;
$secondary-purple-50: #8533a6;
$secondary-purple-40: #ae43d9;
$secondary-gray-80: #3e3a40;
$secondary-gray-70: #736d70;
$secondary-gray-60: #969699;
$secondary-gray-40: #C8C8CC;
$secondary-gray-20: #e5e5ea;

/** STATUS **/
$status-success: #d9ffee;
$status-warning: #ffd3cc;
$status-error: #e6b8d0;

/** FONT FAMILY **/
$font-family: "Montserrat-Regular";
$font-family-regular: "Montserrat-Regular";
$font-family-medium: "Montserrat-Medium";
$font-family-semibold: "Montserrat-Semibold";
$font-family-bold: "Montserrat-Bold";

/* FONTS */
@font-face {
  font-family: $font-family-regular;
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: $font-family-medium;
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: $font-family-semibold;
  font-weight: 600;
  font-style: normal;
  src: url("/assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: $font-family-bold;
  font-weight: 700;
  font-style: normal;
  src: url("/assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

/** H1 **/
$font-size-h1-desktop: 26px;
$font-size-h1-medium: 26px;
$font-size-h1-mobile: 26px;

$line-height-h1-desktop: 32px;
$line-height-h1-medium: 32px;
$line-height-h1-mobile: 32px;

$letter-spacing-h1-desktop: 0.6px;
$letter-spacing-h1-medium: 0.6px;
$letter-spacing-h1-mobile: 0.6px;

$font-weight-h1-desktop: bold;
$font-weight-h1-medium: bold;
$font-weight-h1-mobile: bold;

$color-h1-desktop: $secondary-gray-20;
$color-h1-medium: $secondary-gray-20;
$color-h1-mobile: $secondary-gray-20;

/** H2 **/
$font-size-h2-desktop: 22px;
$font-size-h2-medium: 22px;
$font-size-h2-mobile: 22px;

$line-height-h2-desktop: 32px;
$line-height-h2-medium: 32px;
$line-height-h2-mobile: 32px;

$letter-spacing-h2-desktop: 0.6px;
$letter-spacing-h2-medium: 0.6px;
$letter-spacing-h2-mobile: 0.6px;

$font-weight-h2-desktop: bold;
$font-weight-h2-medium: bold;
$font-weight-h2-mobile: bold;

$color-h2-desktop: $secondary-gray-20;
$color-h2-medium: $secondary-gray-20;
$color-h2-mobile: $secondary-gray-20;

/** H3 **/
$font-size-h3-desktop: 18px;
$font-size-h3-medium: 18px;
$font-size-h3-mobile: 18px;

$line-height-h3-desktop: 24px;
$line-height-h3-medium: 24px;
$line-height-h3-mobile: 24px;

$letter-spacing-h3-desktop: 0.6px;
$letter-spacing-h3-medium: 0.6px;
$letter-spacing-h3-mobile: 0.6px;

$font-weight-h3-desktop: 600;
$font-weight-h3-medium: 600;
$font-weight-h3-mobile: 600;

$color-h3-desktop: $secondary-gray-20;
$color-h3-medium: $secondary-gray-20;
$color-h3-mobile: $secondary-gray-20;

/** H4 **/
$font-size-h4-desktop: 14px;
$font-size-h4-medium: 14px;
$font-size-h4-mobile: 14px;

$line-height-h4-desktop: 20px;
$line-height-h4-medium: 20px;
$line-height-h4-mobile: 20px;

$letter-spacing-h4-desktop: 0.6px;
$letter-spacing-h4-medium: 0.6px;
$letter-spacing-h4-mobile: 0.6px;

$font-weight-h4-desktop: 600;
$font-weight-h4-medium: 600;
$font-weight-h4-mobile: 600;

$color-h4-desktop: $secondary-gray-20;
$color-h4-medium: $secondary-gray-20;
$color-h4-mobile: $secondary-gray-20;

/** H5 **/
$font-size-h5-desktop: 12px;
$font-size-h5-medium: 12px;
$font-size-h5-mobile: 12px;

$line-height-h5-desktop: 16px;
$line-height-h5-medium: 16px;
$line-height-h5-mobile: 16px;

$letter-spacing-h5-desktop: 0.6px;
$letter-spacing-h5-medium: 0.6px;
$letter-spacing-h5-mobile: 0.6px;

$font-weight-h5-desktop: 600;
$font-weight-h5-medium: 600;
$font-weight-h5-mobile: 600;

$color-h5-desktop: $secondary-gray-20;
$color-h5-medium: $secondary-gray-20;
$color-h5-mobile: $secondary-gray-20;

/** P **/
$font-size-p-desktop: 14px;
$font-size-p-medium: 14px;
$font-size-p-mobile: 14px;

$line-height-p-desktop: 24px;
$line-height-p-medium: 24px;
$line-height-p-mobile: 24px;

$letter-spacing-p-desktop: 0.4px;
$letter-spacing-p-medium: 0.4px;
$letter-spacing-p-mobile: 0.4px;

$font-weight-p-desktop: 500;
$font-weight-p-medium: 500;
$font-weight-p-mobile: 500;

/** P-SMALL **/
$font-size-p-small-desktop: 11px;
$font-size-p-small-medium: 11px;
$font-size-p-small-mobile: 11px;

$line-height-p-small-desktop: 16px;
$line-height-p-small-medium: 16px;
$line-height-p-small-mobile: 16px;

$letter-spacing-p-small-desktop: 0.4px;
$letter-spacing-p-small-medium: 0.4px;
$letter-spacing-p-small-mobile: 0.4px;

$font-weight-p-small-desktop: 500;
$font-weight-p-small-medium: 500;
$font-weight-p-small-mobile: 500;

/** MEDIA QUERIES **/

/* GREATER THAN (min-width) */
$media-query-lt-sm: 599px;
$media-query-lt-md: 959px;
$media-query-lt-lg: 1279px;
$media-query-lt-xl: 1919px;

/* GREATER THAN (min-width) */
$media-query-gt-xs: 600px;
$media-query-gt-sm: 960px;
$media-query-gt-md: 1280px;
$media-query-gt-lg: 1920px;

/*** TABLE ***/
$table-view-height: calc(100vh - 253px);

/** ENTITIES **/

$sound-typography: mat.m2-define-typography-config($font-family: $font-family);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`

$my-primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$my-theme: mat.m2-define-light-theme((color: (primary: $my-primary,
        accent: $my-accent ),
      typography: $sound-typography ));


/****** SIZING ******/
.#{$applicationPrefix}-full-width {
  width: 100%;
}

*,
::before,
::after {
  border-color: initial;
  outline: none !important;
}

img {
  height: initial;
}

/********************/
/****** FORMS *******/
/********************/

.#{$applicationPrefix}-form {

  /*** SPACING ***/
  .margin-bottom {
    margin-bottom: 12px;
  }
}

.bold {
  font-family: $font-family-bold;
}

.semi-bold {
  font-family: $font-family-semibold;
}

.form-fields-rich-text-container__content .NgxEditor__MenuBar {
  background-color: #322e33;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.form-fields-rich-text-container__content .NgxEditor__Content {
  background-color: #322e33;
}

.form-fields-rich-text-container__content .NgxEditor {
  border-color: #3e3a40;

  &:hover {
    border-color: #575357;
  }

  &:focus-within {
    border-color: #e5e7eb;
  }

  ol,
  ul,
  menu {
    padding-left: 0px;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
}

/********************/
/****** MATERIAL OVERWRITES *******/
/********************/

/****** MAT STEPPER OVERWRITE *****/

.#{$applicationPrefix}-mat-stepper.register .mat-horizontal-stepper-header {
  padding: 12px 32px;
}

.#{$applicationPrefix}-mat-stepper.register .mat-horizontal-stepper-header-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.#{$applicationPrefix}-mat-stepper.register .mat-horizontal-content-container {
  padding: 0px;
}

.mat-horizontal-content-container {
  padding: 0px !important;
}

.#{$applicationPrefix}-mat-stepper.register .mat-horizontal-stepper-content {
  padding: 32px 32px 32px 32px;
}

.#{$applicationPrefix}-mat-stepper.register .mat-horizontal-stepper-content[aria-expanded="false"] {
  padding: 0px;
}

.mat-mdc-card {
  padding: 24px !important;
}

.language-selector {
  background-color: #fcfcfc;
  border-radius: 7%;
  padding-left: 2px;
  font-size: 12px !important;
}

.sound-table {
  width: 100%;

  a {
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.6);
    text-decoration: none;
  }

  a:hover {
    color: rgba(0, 0, 0, 1);
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    cursor: pointer;
  }
}

/*** VISIBILTY ***/

// xs.
.visible-lg {
  display: none !important;
}

.hidden-lg {
  display: block !important;
}

.visible-md {
  display: none !important;
}

.hidden-md {
  display: block !important;
}

.visible-sm {
  display: none !important;
}

.hidden-sm {
  display: block !important;
}

.visible-xm {
  display: none !important;
}

.hidden-xm {
  display: block !important;
}

.visible-xs {
  display: block !important;
}

.hidden-xs {
  display: none !important;
}

// gt-xs
@media (min-width: 600px) {
  .visible-lg {
    display: none !important;
  }

  .hidden-lg {
    display: block !important;
  }

  .visible-md {
    display: none !important;
  }

  .hidden-md {
    display: block !important;
  }

  .visible-sm {
    display: none !important;
  }

  .hidden-sm {
    display: block !important;
  }

  .visible-xs {
    display: none !important;
  }

  .hidden-xs {
    display: block !important;
  }

  .visible-xm {
    display: block !important;
  }

  .hidden-xm {
    display: none !important;
  }
}

// no flex layout equivalent
@media (min-width: 768px) {
  .visible-lg {
    display: none !important;
  }

  .hidden-lg {
    display: block !important;
  }

  .visible-md {
    display: none !important;
  }

  .hidden-md {
    display: block !important;
  }

  .visible-xm {
    display: none !important;
  }

  .hidden-xm {
    display: block !important;
  }

  .visible-xs {
    display: none !important;
  }

  .hidden-xs {
    display: block !important;
  }

  .visible-sm {
    display: block !important;
  }

  .hidden-sm {
    display: none !important;
  }
}

// gt-sm
@media (min-width: 960px) {
  .visible-lg {
    display: none !important;
  }

  .hidden-lg {
    display: block !important;
  }

  .visible-sm {
    display: none !important;
  }

  .hidden-sm {
    display: block !important;
  }

  .visible-xm {
    display: none !important;
  }

  .hidden-xm {
    display: block !important;
  }

  .visible-xs {
    display: none !important;
  }

  .hidden-xs {
    display: block !important;
  }

  .visible-md {
    display: block !important;
  }

  .hidden-md {
    display: none !important;
  }
}

// gt-md
@media (min-width: 1200px) {
  .visible-md {
    display: none !important;
  }

  .hidden-md {
    display: block !important;
  }

  .visible-sm {
    display: none !important;
  }

  .hidden-sm {
    display: block !important;
  }

  .visible-xm {
    display: none !important;
  }

  .hidden-xm {
    display: block !important;
  }

  .visible-xs {
    display: none !important;
  }

  .hidden-xs {
    display: block !important;
  }

  .visible-lg {
    display: block !important;
  }

  .hidden-lg {
    display: none !important;
  }
}

/**** CONTAINER ****/

.container-wide {
  padding-left: 32px;
  padding-right: 32px;
}

.container {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 556px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 748px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 972px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1180px;
  }
}

/** TOAST - HIDDEN FOR NOW **/

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.ngx-toastr {
  /* Auto layout */
  padding: 1.143rem 1.714rem 1.714rem;
  gap: 0.571rem;
  width: 29.286rem;

  @media (max-width: 767px) {
    width: 19.286rem;
  }

  background: #322e33;

  &.toast-warning {
    border: 1px solid #ffd3cc;
    border-radius: 3px;
    color: #ffd3cc;
  }

  &.toast-success {
    border: 1px solid #d9ffee;
    border-radius: 3px;
    color: #d9ffee;
  }

  &.toast-error {
    border: 1px solid #e6b8d0;
    border-radius: 3px;
    color: #e6b8d0;
  }

  .toast-title,
  .alert-card-title {
    margin-bottom: 0.571rem;
    font-family: $font-family-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    background-color: transparent;
  }

  .toast-message,
  .alert-card-message {
    background-color: transparent;
    word-wrap: break-word;
    font-family: $font-family-regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}

.toast-container .toast,
.alert-card-content {
  position: relative;
  overflow: hidden;
  margin: 0 0 3px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  padding-left: 53px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 2px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  box-shadow: 0 0px 6px #ccc;
  color: #ffffff;
  font-size: 14px;
  opacity: 0.95;
}

.alert-card-content {
  font-size: 15px;
  box-shadow: none;
  line-height: 22px;
  opacity: 0.8;
}

.alert-card-content {
  position: initial;
  margin: 0px;
  width: auto;
}

.toast-container .toast:hover {
  box-shadow: 0 0px 6px #ccc;
  opacity: 1;
  cursor: pointer;
}

.toast-top-right {
  top: 20px;
  right: 20px;
}

/** MATERIAL SPINNER **/

.mat-mdc-progress-spinner circle {
  stroke: $primary-purple-50;
}

/** NGX LOADING BAR **/

#loading-bar-spinner {
  top: 25px !important;
  left: 230px !important;
}

/** LOADING BAR **/

/* Make clicks pass-through */
#loading-bar,
#loading-bar-spinner {
  pointer-events: none;
  -webkit-pointer-events: none;
  -webkit-transition: 350ms linear all;
  -moz-transition: 350ms linear all;
  -o-transition: 350ms linear all;
  transition: 350ms linear all;
}

#loading-bar .bar {
  -webkit-transition: width 350ms;
  -moz-transition: width 350ms;
  -o-transition: width 350ms;
  transition: width 350ms;

  background: #29d;
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
}

/* Fancy blur effect */
#loading-bar .peg {
  position: absolute;
  width: 70px;
  right: 0;
  top: 0;
  height: 2px;
  opacity: 0.45;
  -moz-box-shadow: #29d 1px 0 6px 1px;
  -ms-box-shadow: #29d 1px 0 6px 1px;
  -webkit-box-shadow: #29d 1px 0 6px 1px;
  box-shadow: #29d 1px 0 6px 1px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

#loading-bar-spinner {
  display: block;
  position: fixed;
  z-index: 10002;
  top: 10px;
  right: 10px;
}

#loading-bar-spinner .spinner-icon {
  width: 14px;
  height: 14px;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: loading-bar-spinner 400ms linear infinite;
  -moz-animation: loading-bar-spinner 400ms linear infinite;
  -ms-animation: loading-bar-spinner 400ms linear infinite;
  -o-animation: loading-bar-spinner 400ms linear infinite;
  animation: loading-bar-spinner 400ms linear infinite;
}

@-webkit-keyframes loading-bar-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes loading-bar-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes loading-bar-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes loading-bar-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/** DROPZONE **/
.sound-file-dropzone {
  border: 1px dashed rgba(0, 0, 0, 0.6);

  &--active {
    background-color: rgba(0, 0, 0, 0.06) !important;
    border: 1px dashed #07a685;
  }
}

.info-text {
  max-width: 600px;
  margin-bottom: 12px;
  line-height: 20px;
  font-size: $font-size-p-small-desktop;

  @media (min-width: 1281px) {
    line-height: 22px;
    font-size: 12px;
  }

  color: rgba(0, 0, 0, 0.7);

  &--divide {
    margin-top: 32px;
  }

  a {
    line-height: 20px;
    font-size: $font-size-p-small-desktop;

    @media (min-width: 1281px) {
      line-height: 22px;
      font-size: 12px;
    }
  }
}

.divide {
  margin-top: 24px !important;
}

::ng-deep .mat-datepicker-content {
  padding-bottom: 12px;
}

.tracks__drop-zone-container {
  border-radius: 3px;

  .sound-file-dropzone {
    cursor: pointer;
    width: 16rem;
    background-color: rgb(50, 46, 51, 1); // bg-primary-dark-purple
    padding-left: 1.143rem
      /* 18.288px */
    ;
    padding-right: 1.143rem
      /* 18.288px */
    ;
    height: 40px;
    border: 1px dashed #736d70;
    border-radius: 3px;

    &--active {
      background: #322e33 !important;
      border: 1px dashed #33ffa2;

      .tracks__drop-zone-container__caption {
        color: #33ffa2;
      }

      .tracks__drop-zone-container__sub-caption {
        color: #33ffa2;
      }
    }
  }

  &__icon {
    margin-left: 0.571rem;
    /* 9.136px ml-2 */
  }

  .mat-icon:hover {
    color: #969699 !important;
  }

  &__caption {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.875rem
      /* 14px */
    ; // text-sm
    line-height: 1.25rem
      /* 20px */
    ;
    font-family: "Montserrat-Regular";
    line-height: 1.43rem;
  }

  &__caption--dropped {
    color: #33ffa2;
  }

  &__sub-caption {
    font-weight: 500;
    font-size: 0.75rem
      /* 12px */
    ; // text-xs
    line-height: 1rem
      /* 16px */
    ;
    color: rgba(150, 150, 153, 1); // text-secondary-gray-60
    font-family: "Montserrat-Regular";
    line-height: 1.43rem;
  }
}

.tracks__drop-zone-container--dropped {
  .sound-file-dropzone {
    border-color: #33ffa2;
  }
}

.tracks__drop-zone-container:hover .sound-file-dropzone {
  background-color: rgba(62, 58, 64, 1); // bg-secondary-gray-80;
}

/*** TABLE ****/

table {
  width: 100%;
}

.paginator {
  .mat-mdc-paginator {
    background-color: transparent;
    margin-top: -8px;
    font-size: $font-size-p-small-desktop;

    @media (min-width: 1281px) {
      font-size: $font-size-p-desktop;
    }

    margin-right: -24px;
  }

  .mat-mdc-paginator-page-size {
    display: none;
  }
}

.scrollable-table .list-content {
  margin-top: 0px !important;
  border: 0px !important;
}

.list-header {
  text-transform: uppercase;
  font-size: 1.857rem;
  line-height: 2.285rem;
}

.list-content {
  margin: 0px !important;
}

.search .mat-mdc-form-field {
  width: 100%;
}

textarea.mat-mdc-input-element.cdk-textarea-autosize {
  resize: vertical !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.search .mat-mdc-form-field-infix {
  border-top: 0px solid rgba(0, 0, 0, 0);
  font-size: $font-size-p-desktop;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.search .mat-mdc-form-field-wrapper {
  padding: 0px;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.search .mat-mdc-form-field-underline {
  bottom: 0px;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.date-input .mat-mdc-form-field-infix {
  width: auto;
}

.mat-expansion-panel {
  background-color: inherit;
  box-shadow: none !important;
}

.selected-filter-text {
  font-style: italic;
}

.mat-column-select {
  left: 0px !important;
}

.sound-table {
  /** BASIC STYLINGS **/

  width: 100%;
  background-color: white;

  &__row {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    &__column {
      min-width: 146px;

      padding-top: 24px;
      padding-bottom: 12px;
      padding-right: 12px;

      &__checkbox {
        width: 64px;
        min-width: 64px;
        padding-bottom: 2px;
        padding-left: 24px;

        &--loading {
          height: 18px;
          width: 18px;
        }
      }

      &__thumbnail {
        &--loading {
          height: 45px;
          margin-top: 4px;
          width: 45px;
          margin-right: 2rem;
        }
      }

      &__checkbox--higher {
        width: 64px;
        min-width: 64px;
        padding-left: 24px;

        &--loading {
          height: 18px;
          width: 18px;
        }
      }

      $titleWidths: (
        90,
        75,
        70,
        60,
        50,
        45
      );
    $valueWidths: (
      60,
      55,
      50,
      45,
      40
    );
  $firstnameWidths: (
    90,
    80,
    70,
    60
  );
$lastnameWidths: (
  85,
  77,
  69,
  61
);
$emailWidths: (
  80,
  75,
  70,
  65,
  60
);
$fileNameWidths: (
  95,
  85,
  75,
  65
);

&__file-name {
  @each $width in $fileNameWidths {
    &--loading--#{$width} {
      height: 18px;
      margin-top: -2px;
      width: $width * 1%;
    }
  }
}

&__created {
  &--loading {
    height: 18px;
    margin-top: -2px;
    width: 60%;
  }
}

&__thumbnail__image-container {
  &--loading {
    height: 35px;
    width: 35px;
  }

  &--loading-50 {
    height: 45px;
    width: 45px;
  }
}

&__thumbnail__image {
  &--loading {
    height: 45px;
    width: 45px;
  }
}

&__creator__image-container {
  &--loading {
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }

  &--loading-50 {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
}

&__creator__image {
  border-radius: 50%;

  &--loading {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}

&__modified {
  &--loading {
    height: 18px;
    margin-top: -2px;
    width: 70 * 1%;
  }
}

&__title {
  @each $width in $titleWidths {
    &--loading--#{$width} {
      height: 18px;
      margin-top: -2px;
      width: $width * 1%;
    }
  }
}

&__firstname {
  @each $width in $firstnameWidths {
    &--loading--#{$width} {
      height: 18px;
      margin-top: -2px;
      width: $width * 1%;
    }
  }
}

&__lastname {
  @each $width in $lastnameWidths {
    &--loading--#{$width} {
      height: 18px;
      margin-top: -2px;
      width: $width * 1%;
    }
  }
}

&__email {
  @each $width in $emailWidths {
    &--loading--#{$width} {
      height: 18px;
      margin-top: -2px;
      width: $width * 1%;
    }
  }
}

&__value {
  @each $width in $valueWidths {
    &--loading--#{$width} {
      height: 18px;
      margin-top: -2px;
      width: $width * 1%;
    }
  }
}
}
}
}

.user-round {
  border-radius: 50%;
}

.sound-form-field {
  font-size: 12px !important;

  @media (min-width: 1300px) {
    font-size: $font-size-p-desktop !important;
  }
}

.property {
  line-height: 27px;

  &__name {
    font-size: $font-size-p-desktop;
    color: rgba(0, 0, 0, 0.7);
  }

  &__value {
    font-size: 16px;
    margin-top: -4px;
  }
}

.disabled {
  h3 {
    color: rgba(0, 0, 0, 0.3);
  }

  p {
    color: rgba(0, 0, 0, 0.24);
  }
}

.disabled ::ng-deep h3 {
  color: rgba(0, 0, 0, 0.3);
}

.disabled ::ng-deep .legend__item__label {
  color: rgba(0, 0, 0, 0.24);
}

.disabled ::ng-deep .serial-number-tile {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.disabled ::ng-deep .mat-icon {
  color: rgba(0, 0, 0, 0.24);
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.disabled ::ng-deep .mat-mdc-radio-label-content {
  color: rgba(0, 0, 0, 0.24);
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.disabled ::ng-deep .mat-mdc-form-field-label {
  color: rgba(0, 0, 0, 0.24);
}

.disabled ::ng-deep .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.12) !important;
}

.disabled ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-hint {
  color: rgba(0, 0, 0, 0.24);
}

.disabled h4 {
  color: rgba(0, 0, 0, 0.24);
}

@media (min-width: 768px) and (max-width: 1300px) {
  ::ng-deep .mat-vertical-content {
    padding: 0 0 24px 20px !important;
  }

  ::ng-deep .mat-vertical-content-container {
    margin-left: -21px !important;

    &.mat-stepper-vertical-line::before {
      border: none !important;
    }
  }

  ::ng-deep .mat-vertical-stepper-header {
    margin-left: -25px;
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  .sound-vertical-stepper ::ng-deep .mat-vertical-content {
    padding: 0 0 24px 20px !important;
  }

  .sound-vertical-stepper ::ng-deep .mat-vertical-content-container {
    margin-left: 36px !important;
  }

  .sound-vertical-stepper ::ng-deep .mat-vertical-content-container.mat-stepper-vertical-line::before {
    border-left-style: solid !important;
    border-left-color: rgba(0, 0, 0, 0.12) !important;
    border-left-width: 1px !important;
  }

  .sound-vertical-stepper ::ng-deep .mat-vertical-stepper-header {
    margin-left: 0px;
  }
}

.step-content-grid-column {
  &__container {
    &--first {}

    &--last {}
  }

  &__divider {
    &--single {
      margin-right: 48px;
    }

    &--double {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.table-button {
  margin-top: -6px;
}

/*TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/
mat-tooltip-component {
  div.mat-tooltip {
    padding: 0.2rem 0.471rem;
    background: #d7ff73;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    font-style: normal;
    font-family: $font-family-medium;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #000000 !important;
    // to make possible place arrow pseudo element outside tooltip with absolute positioning
    overflow: visible;
    position: relative;

    // Arrow
    &::before {
      position: absolute;
      content: "";
      display: inline-block;
      background-color: #d7ff73;
      /* -webkit-clip-path: polygon(50% 0, 0 50%, 50% 100%); */
      clip-path: polygon(50% 0, 50% 100%, 0 50%);
      left: 50%;
      width: 15px;
      height: 15px;
      bottom: -6px;
      transform: rotate(270deg);
    }
  }
}

.mat-mdc-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
  overflow: hidden;
}

.entity {
  &__content {
    margin-left: auto;
    margin-right: auto;

    &__headline {
      min-height: 28px;

      @media (min-width: 1281px) {
        min-height: 38px;
      }
    }

    &__actions {
      &__divider {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        width: 1px;
        height: 75%;
      }
    }

    &--content {
      width: 100%;
    }

    &--actions {
      padding-bottom: 24px;
      background-color: white;
    }
  }

  &__collaboration {
    &__container {
      padding-top: 12px;
    }

    &__content {
      height: calc(100vh - 258px);
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-right: 0px;
      border-top: none;
      overflow-y: hidden;
    }
  }

  &__chat {
    position: relative;
    background-color: white;
    height: 100%;

    @media (min-width: 768px) and (max-width: 1300px) {
      width: 35vw;
    }

    &__container {
      @media (min-width: 768px) and (max-width: 1300px) {
        width: 60vw;
      }

      width: 36vw;
    }
  }

  &__processing {
    position: fixed;
    right: 12px;
    margin-top: 4px;

    &__box {
      background-color: rgba(0, 0, 0, 0.04);
      padding: 22px;
    }

    &__action-label {
      color: rgba(0, 0, 0, 0.5);
      font-size: 13px;
    }

    &__action-button {
      margin-bottom: 12px;
    }

    &__title {
      margin-bottom: 21px;
      margin-top: 32px;
      font-size: 13px;
      text-align: left;
      padding-left: 6px;

      &--no-margin-top {
        margin-top: 0px;
      }

      @media (min-width: 1281px) {
        font-size: 16px;
      }
    }
  }

  &__actions {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.9);
}

@media (max-width: 1280px) {
  .mat-mdc-menu-item {
    font-size: 12px;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-mdc-option-user {
  height: unset !important;
  line-height: 34px !important;
}

.entity-list-table {

  .column,
  .column-heading {
    min-width: 200px;
    max-width: 200px;
    padding-right: 24px !important;
    padding-top: 1px;

    &--first {
      min-width: 300px;
      max-width: 300px;
    }
  }
}

/** MAT-MENU **/

.download-button__menu-background+div .mat-mdc-menu-panel,
.share-button__menu-background+div .mat-menu-panel {
  background: #322e33 !important;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.5);
  border: none;
}

.mat-mdc-menu-panel {
  border-radius: 3px !important;
  border: 1px solid $secondary-gray-80;
  box-sizing: border-box;
  background: #3e3a40;
  min-height: unset !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-mdc-menu-trigger--opened .mat-mdc-icon {
  color: $primary-white;
}

button.mat-mdc-menu-item {
  min-width: 200px !important;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px $secondary-gray-80;
}

button.mat-mdc-menu-item:last-child {
  box-shadow: none;
}

.mat-mdc-menu-item {
  display: flex !important;

  .mat-icon {
    margin: 4px !important;
  }
}

.mat-mdc-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: $secondary-gray-80 !important;
}

.mat-mdc-menu-content {
  .text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(202, 138, 4, var(--tw-text-opacity));
  }

  .bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 249, 195, var(--tw-bg-opacity));
  }

  .hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 240, 138, var(--tw-bg-opacity));
  }

  .hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(133, 77, 14, var(--tw-text-opacity));
  }

  .text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
}

textarea {
  &.multiple-lines {
    height: auto !important;

    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    ::ng-deep div.mat-form-field-infix {

      // padding: .3em 0;
      textarea.mat-input-element {
        height: 19px;
      }
    }
  }
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  @media (max-width: 1280px) {
    font-size: 12px;
  }
}

.mat-mdc-header-cell {
  font-size: 10px;
  line-height: 20px;

  @media (min-width: 1281px) {
    font-size: $font-size-p-small-desktop;
    line-height: 22px;
  }
}

.mat-mdc-cell,
.mat-mdc-footer-cell {
  word-break: break-word;
  font-size: 12px;
  line-height: 18px;

  @media (min-width: 1281px) {
    font-size: 13px;
    line-height: 20px;
  }
}

.filter {
  &__title {
    max-height: 40px;
    line-height: 18px;

    h4 {
      font-size: $font-size-p-small-desktop;
      line-height: 20px;
      margin-top: 2px;
    }
  }
}

.entity-card {
  display: block;
  margin-bottom: 24px;
}

:root {
  --cropper-outline-color: rgba(255, 255, 255, 0.7);
}

.mat-mdc-form-field {

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  div.mat-mdc-form-field-infix {

    // padding: .3em 0;
    textarea.mat-mdc-input-element {
      height: 24px;
      line-height: 20px;
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-select-trigger {
  width: 100%;
}

.box-shadow {
  &--top {
    box-shadow: 0 -4px 24px -15px #e3e3e3;
  }

  &--right {
    box-shadow: -5px 0 5px -5px #333;
  }

  &--bottom {
    box-shadow: 0 5px 5px -5px #333;
  }

  &--left {
    box-shadow: 5px 0 5px -5px #333;
  }

  &--all {
    box-shadow: 0 0 5px #333;
  }
}

.container-small {
  min-height: 100%;
  align-items: flex-start;
  display: flex;
  justify-content: center;

  &>* {
    max-width: 700px;
    width: 700px;
  }
}

.container-medium {
  height: 100%;
  align-items: flex-start;
  display: flex;
  justify-content: center;

  &>* {
    max-width: 1200px;
    width: 1200px;
  }
}

textarea.mat-mdc-input-element.cdk-textarea-autosize {
  resize: vertical !important;
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  border-color: rgba(245, 245, 245, 1);
}

.mat-mdc-row:hover .mat-mdc-cell {
  background-color: rgba(0, 0, 0, 0.02);
}

.mat-mdc-row.selected .mat-mdc-cell {
  background-color: rgba(224, 242, 254, 0.5);
}

tr.mat-mdc-row,
tr.mat-mdc-footer-row {
  height: 50px !important;
}

.refine-search {
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.column-heading--actions,
.column--actions {
  min-width: 70px !important;
  max-width: 70px !important;
}

.search {
  font-family: "Montserrat-Regular";
  font-weight: 400;

  .form-fields-text-container {
    margin-bottom: 0 !important;
  }

  .enter-button {
    position: absolute;
  }
}

.column__user__image--loading {
  width: 220px;
  height: 220px;
}

.entity__content__headline--sticky-container {
  min-height: 44px !important;
}

.entity__content__headline--sticky {
  background-color: #fcfcfc;
}

.cdk-global-scrollblock {
  position: unset;
}

.dialog-panel {
  width: 90vw;

  @media (min-width: 768px) {
    width: 50vw;
  }
}

/* **************************************** */
/* *********** SOUND THEME ************ */
/* **************************************** */
/* * CONTAINER * */
.container {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
}

@media (min-width: $media-query-gt-xs) {
  .container {
    width: 100%;
    width: 556px;
  }
}

@media (min-width: $media-query-gt-sm) {
  .container {
    width: 942px;
  }
}

@media (min-width: $media-query-gt-md) {
  .container {
    width: 1220px;
  }
}

/* * HELPER * */
.#{$namespace}-full-width {
  width: 100%;
}

.#{$namespace}-text-uppercase {
  text-transform: uppercase;
}

* {
  box-sizing: border-box;
}

/* * HTML, BODY * */
html,
body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  background-size: cover;

  margin: 0px;
  padding: 0px;

  word-break: break-word;

  font-family: "Montserrat-Medium", "Helvetica Neue", sans-serif;
  color: $secondary-gray-40;

  font-size: $font-size-p-desktop;
  line-height: $line-height-p-desktop;
  font-weight: $font-weight-p-desktop;

  font-family: "Montserrat-Medium", "Helvetica Neue", sans-serif;

  background: linear-gradient(270deg, transparent 90%, #5C2473 140%),  
  url('https://imagedelivery.net/3cqLyQwu3OCaEPcs2GMsQQ/225ee22b-0241-4aec-3350-c26e36a16c00/XXL');
  background-repeat: no-repeat;
  background-size: cover;
  word-break: break-word;

  // Tablet devices
  @media (max-width: 768px) {
    background: linear-gradient(270deg, transparent 90%, #5C2473 140%),  
    url('https://imagedelivery.net/3cqLyQwu3OCaEPcs2GMsQQ/1b236e1b-9246-4151-6bd2-4f978bb6fb00/XXL');
  }

  // Mobile devices
  @media (max-width: 480px) {
    background: linear-gradient(270deg, transparent 90%, #5C2473 140%), 
    url('https://imagedelivery.net/3cqLyQwu3OCaEPcs2GMsQQ/2a5eed12-5e7c-43da-8ccf-e178a361a300/XXL');
  }

  @media (min-width: 1281px) {
    font-size: $font-size-p-desktop;
  }

  .content-teaser {
    padding: 160px 240px 160px 480px;
    background: $primary-dark-purple;
  }
}

body {
  min-height: 100vh;
}

/* * TYPINGS * */
h1 {
  text-transform: uppercase;
}

h1,
h2,
h3,
h4 {
  font-family: $font-family-bold;
  margin: 0px;
}

h1 {
  font-size: $font-size-h1-mobile;
  letter-spacing: $letter-spacing-h1-mobile;
  line-height: $line-height-h1-mobile;
  color: $color-h1-mobile;
  font-weight: $font-weight-h1-mobile;
}

h2 {
  font-size: $font-size-h2-mobile;
  letter-spacing: $letter-spacing-h2-mobile;
  line-height: $line-height-h2-mobile;
  color: $color-h2-mobile;
  font-weight: $font-weight-h2-mobile;
}

h3 {
  font-size: $font-size-h3-mobile;
  letter-spacing: $letter-spacing-h3-mobile;
  line-height: $line-height-h3-mobile;
  color: $color-h3-mobile;
  font-weight: $font-weight-h3-mobile;
}

h4 {
  font-size: $font-size-h4-mobile;
  letter-spacing: $letter-spacing-h4-mobile;
  line-height: $line-height-h4-mobile;
  color: $color-h4-mobile;
  font-weight: $font-weight-h4-mobile;
}

h5 {
  font-size: $font-size-h5-mobile;
  letter-spacing: $letter-spacing-h5-mobile;
  line-height: $line-height-h5-mobile;
  color: $color-h5-mobile;
  font-weight: $font-weight-h5-mobile;
}

.accented {
  font-family: $font-family-regular;
  font-weight: bold;
}

a {
  font-family: $font-family-medium;

  color: $secondary-gray-60;

  font-weight: 500;

  font-size: $font-size-p-desktop;
  line-height: 24px;

  text-decoration: none;

  transition: color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    cursor: pointer;
    color: $primary-green-50;
  }

  &.disabled {
    cursor: default;
    color: $secondary-gray-60;

    &:hover {
      cursor: default;
      color: $secondary-gray-60;
    }
  }

  // &:focus {
  //   border: 1px solid #6e94c7;
  //   box-sizing: border-box;
  //   filter: drop-shadow(0px 0px 10px rgba(110, 148, 199, 0.5));
  // }
  &.small,
  .small {
    line-height: 16px;
    font-size: $font-size-p-small-desktop;
  }

  &.green {
    color: $primary-green-50;
  }

  &.navigation-link {
    z-index: 100;
    border: 1px solid transparent;
    font-family: $font-family-bold;

    &:hover {
      color: $primary-white;

      .mat-icon {
        color: $primary-white;
      }
    }

    // &:focus {
    //   background-color: $primary-dark-purple;
    // }

    .mat-icon {
      color: $secondary-gray-60;
    }

    &.active {
      background-color: $primary-dark-purple;
      box-shadow: inset 8px 0px 0px #8533a6;

      color: $primary-white;

      // font-weight: bold;
      .mat-icon {
        color: $primary-white;
      }

      &--borderless {
        background-color: inherit;
        box-shadow: none;
      }
    }

    &--logo {
      .mat-icon {
        color: $primary-white;
      }

      &.active {
        background-color: inherit;
        box-shadow: none;
      }

      &:hover {
        color: $secondary-gray-20;

        .mat-icon {
          color: $secondary-gray-20;
        }
      }

      // &:focus {
      //   background-color: inherit;
      // }
    }
  }

  &.dropzone {
    color: inherit;

    &:hover {
      cursor: pointer;
      color: inherit;
    }
  }
}

p {
  font-family: $font-family-regular;
  color: $primary-white;
  font-size: $font-size-p-mobile;
  letter-spacing: $letter-spacing-p-mobile;
  line-height: $line-height-p-mobile;
  font-weight: $font-weight-p-mobile;
  margin: 0px;

  &.accented {
    font-weight: bold;
  }

  &.large {
    text-transform: uppercase;
  }

  &.small {
    font-family: $font-family-regular;
    color: $primary-white;
    font-size: $font-size-p-small-mobile;
    letter-spacing: $letter-spacing-p-small-mobile;
    line-height: $line-height-p-small-mobile;
    font-weight: $font-weight-p-small-mobile;
    margin: 0px;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: $font-size-h1-medium;
    letter-spacing: $letter-spacing-h1-medium;
    line-height: $line-height-h1-medium;
    color: $color-h1-medium;
    font-weight: $font-weight-h1-medium;
  }

  h2 {
    font-size: $font-size-h2-medium;
    letter-spacing: $letter-spacing-h2-medium;
    line-height: $line-height-h2-medium;
    color: $color-h2-medium;
    font-weight: $font-weight-h2-medium;
  }

  h3 {
    font-size: $font-size-h3-medium;
    letter-spacing: $letter-spacing-h3-medium;
    line-height: $line-height-h3-medium;
    color: $color-h3-medium;
    font-weight: $font-weight-h3-medium;
  }

  h4 {
    font-size: $font-size-h4-medium;
    letter-spacing: $letter-spacing-h4-medium;
    line-height: $line-height-h4-medium;
    color: $color-h4-medium;
    font-weight: $font-weight-h4-medium;
  }

  h5 {
    font-size: $font-size-h5-medium;
    letter-spacing: $letter-spacing-h5-medium;
    line-height: $line-height-h5-medium;
    color: $color-h5-medium;
    font-weight: $font-weight-h5-medium;
  }

  p {
    font-size: $font-size-p-medium;
    letter-spacing: $letter-spacing-p-medium;
    line-height: $line-height-p-medium;
    font-weight: $font-weight-p-medium;

    &.large {
      text-transform: uppercase;
    }

    &.accented {
      font-weight: bold;
    }

    &.small {
      font-size: $font-size-p-small-medium;
      letter-spacing: $letter-spacing-p-small-medium;
      line-height: $line-height-p-small-medium;
      font-weight: $font-weight-p-medium;
    }
  }
}

@media (min-width: 1281px) {
  h1 {
    font-size: $font-size-h1-desktop;
    letter-spacing: $letter-spacing-h1-desktop;
    line-height: $line-height-h1-desktop;
    color: $color-h1-desktop;
    font-weight: $font-weight-h1-desktop;
  }

  h2 {
    font-size: $font-size-h2-desktop;
    letter-spacing: $letter-spacing-h2-desktop;
    line-height: $line-height-h2-desktop;
    color: $color-h2-desktop;
    font-weight: $font-weight-h2-desktop;
  }

  h3 {
    font-size: $font-size-h3-desktop;
    letter-spacing: $letter-spacing-h3-desktop;
    line-height: $line-height-h3-desktop;
    color: $color-h3-desktop;
    font-weight: $font-weight-h3-desktop;
  }

  h4 {
    font-size: $font-size-h4-desktop;
    letter-spacing: $letter-spacing-h4-desktop;
    line-height: $line-height-h4-desktop;
    color: $color-h4-desktop;
    font-weight: $font-weight-h4-desktop;
  }

  h5 {
    font-size: $font-size-h5-desktop;
    letter-spacing: $letter-spacing-h5-desktop;
    line-height: $line-height-h5-desktop;
    color: $color-h5-desktop;
    font-weight: $font-weight-h5-desktop;
  }

  p {
    font-size: $font-size-p-desktop;
    letter-spacing: $letter-spacing-p-desktop;
    line-height: $line-height-p-desktop;
    font-weight: $font-weight-p-desktop;

    &.large {
      text-transform: uppercase;
    }

    &.accented {
      font-weight: bold;
    }

    &.small {
      font-size: $font-size-p-small-desktop;
      letter-spacing: $letter-spacing-p-small-desktop;
      line-height: $line-height-p-small-desktop;
      font-weight: $font-weight-p-desktop;
    }
  }
}

/** BUTTONS **/

button {
  border-radius: 3px;
  box-shadow: none;

  letter-spacing: 0.6px;

  font-size: $font-size-p-desktop;
  font-family: $font-family-medium;

  line-height: 18px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  // SIZING
  &.small {
    font-size: $font-size-p-small-desktop;
    height: 24px;
    min-width: 24px;
    padding: 2px 0px;

    /** BUTTON ICON PADDING **/
    &.button-icon-padding {
      &--all-around {
        padding-right: 12px;
        padding-left: 12px;
      }

      &--start {
        padding-right: 6px;
        padding-left: 12px;
      }

      &--end {
        padding-right: 12px;
        padding-left: 6px;
      }
    }
  }

  &.medium {
    height: 32px;
    min-width: 32px;
    padding: 6px 0px;

    /** BUTTON ICON PADDING **/
    &.button-icon-padding {
      &--all-around {
        padding-right: 16px;
        padding-left: 16px;
      }

      &--start {
        padding-left: 16px;
        padding-right: 8px;
      }

      &--end {
        padding-right: 16px;
        padding-left: 8px;
      }
    }
  }

  &.large {
    height: 40px;
    min-width: 40px;
    padding: 4px 0px;

    /** BUTTON ICON PADDING **/
    &.button-icon-padding {
      &--all-around {
        padding-right: 24px;
        padding-left: 24px;
      }

      &--start {
        padding-left: 14px;
        padding-right: 0px;
      }

      &--end {
        padding-right: 24px;
        padding-left: 12px;
      }
    }
  }

  &.inherit {
    height: inherit;
    min-width: inherit;
    max-width: inherit;
    width: 100%;

    &.button-icon-padding {
      &--all-around {
        padding-right: 24px;
        padding-left: 24px;
      }

      &--start {
        padding-left: 24px;
        padding-right: 12px;
      }

      &--end {
        padding-right: 24px;
        padding-left: 12px;
      }
    }
  }

  // COLORS
  // DEFAULT
  &.default {
    color: $secondary-gray-20;
    background: $primary-dark-purple;

    .mat-icon {
      color: $secondary-gray-20;

      &:hover {
        color: $secondary-gray-20;
      }
    }

    &:hover {
      background: $secondary-gray-80;
    }

    // &:focus {
    //   border: 1px solid #6e94c7;
    //   box-sizing: border-box;
    //   box-shadow: 0px 0px 10px rgba(110, 148, 199, 0.5);
    // }
    &:active {
      background: $secondary-gray-70;
    }
  }

  // GREEN
  &.green {
    color: $primary-dark-purple;
    background: $primary-green-50;

    .mat-icon {
      color: $primary-dark-purple;

      &:hover {
        color: $primary-dark-purple;
      }
    }

    &:hover {
      background: $secondary-green-60;
    }

    // &:focus {
    //   border: 1px solid $primary-white;
    //   box-sizing: border-box;
    //   box-shadow: 0px 0px 10px rgba(110, 148, 199, 0.5);
    // }
    &:active {
      background: $secondary-green-70;
    }
  }

  // PURPLE
  &.purple {
    background: $primary-purple-50;
    color: $primary-white;

    .mat-icon {
      color: $primary-white;

      &:hover {
        color: $primary-white;
      }
    }

    &:hover {
      background: $secondary-purple-60;
    }

    // &:focus {
    //   border: 1px solid #6e94c7;
    //   box-sizing: border-box;
    //   box-shadow: 0px 0px 10px rgba(110, 148, 199, 0.5);
    // }
    &:active {
      background: $secondary-purple-70;
    }
  }

  // PURPLE
  &.red {
    background: $primary-red-50;
    color: $primary-white;

    .mat-icon {
      color: $primary-white;

      &:hover {
        color: $primary-white;
      }
    }

    &:hover {
      background: $secondary-red-60;
    }

    // &:focus {
    //   border: 1px solid #6e94c7;
    //   box-sizing: border-box;
    //   box-shadow: 0px 0px 10px rgba(110, 148, 199, 0.5);
    // }
    &:active {
      background: $secondary-purple-70;
    }
  }

  &:disabled,
  &.disabled {
    color: $secondary-gray-80 !important;
    background: $primary-dark-purple !important;
    cursor: default;

    .mat-icon {
      color: $secondary-gray-80;

      &:hover {
        color: $secondary-gray-80;
      }
    }

    &:hover {
      color: $secondary-gray-80;
      background: $primary-dark-purple;
      cursor: default;
    }

    // &:focus {
    //   color: $secondary-gray-80;
    //   background: $primary-dark-purple;
    //   cursor: default;
    // }
    &:active {
      color: $secondary-gray-80;
      background: $primary-dark-purple;
      cursor: default;
    }
  }
}

/** ICON **/
.mat-icon {
  color: $secondary-gray-60;

  &:hover {
    color: $primary-white;
  }

  &.active {
    color: $primary-red !important;
  }

  &.check {
    color: $primary-green-50;

    &:hover {
      color: $primary-white;
    }

    &.always-green {
      &:hover {
        color: $primary-green-50;
      }
    }
  }

  &.enabled {
    color: $secondary-purple-40 !important;

    &:hover {
      color: $secondary-gray-60 !important;
    }
  }

  &.disabled {
    color: $secondary-gray-80;

    &:hover {
      color: $secondary-gray-80;
    }
  }

  &.expander {
    color: $secondary-gray-60;

    &.drop-down {
      transform: rotate(90deg);
    }
  }

  &.purple {
    color: $secondary-purple-50;

    &:hover {
      color: $secondary-purple-50;
    }
  }

  &.menu {
    color: $secondary-gray-60 !important;
  }

  &.favorite {
    color: $primary-red-50 !important;
  }

  &.large {
    font-size: 32px !important;
    height: 32px !important;
    width: 32px !important;
    margin-left: 4px;
    margin-right: 4px;

    &--extended-logo {
      width: auto !important;
      height: auto !important;
    }

    &--extended-partner {
      width: auto !important;
      height: 35px !important;
    }
  }

  &.xtra-large {
    font-size: 70px !important;
    height: 70px !important;
    width: 70px !important;
  }

  &.play-variation {
    font-size: 48px !important;
    height: 48px !important;
    width: 48px !important;
  }

  &.xtra-xtra-large {
    &--white {
      color: $primary-white;
    }

    font-size: 124px !important;
    height: 124px !important;
    width: 124px !important;
    stroke-width: 2px;
  }

  &.medium {
    font-size: 28px !important;
    height: 28px !important;
    width: 28px !important;
    margin-left: 2px;
    margin-right: 2px;
  }

  &.small {
    font-size: 24px !important;
    height: 24px !important;
    width: 24px !important;
  }

  &.logo {
    color: $primary-white;

    &:hover {
      color: $secondary-gray-60;
    }
  }

  &.extended-logo {
    &--large {
      width: 146px !important;
      height: 32px !important;
    }

    &--small {
      width: 110px !important;
      height: 24px !important;
    }
  }
}

mat-icon.mat-icon.close-navigation {
  cursor: pointer;
  stroke: #969699;

  &:hover {
    stroke: #ffffff;
  }
}

/** SIDEBAR **/
.sidebar {
  background: $primary-no-black;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 32px 64px rgba(0, 0, 0, 0.1),
    0px 16px 32px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  width: 64px;

  &--expanded {
    width: 226px;

    @media (max-width: 767px) {
      width: 60px;
    }
  }

  &__container {
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: $primary-dark-purple;
    }

    ::-webkit-scrollbar {
      width: 4px;
      background-color: $primary-dark-purple;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
      /* background-color: $secondary-gray-70;*/
    }

    // Für Firefox
    scrollbar-width: thin;
    scrollbar-color: #2A262B #2A262B;
  }

  .sub-sub-navigation__list {
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: $primary-dark-purple;
    }

    ::-webkit-scrollbar {
      width: 4px;
      background-color: $primary-dark-purple;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: $secondary-gray-70;
    }
  }
}

.dialog-content {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $primary-dark-purple;
  }

  ::-webkit-scrollbar {
    width: 4px;
    background-color: $primary-dark-purple;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $secondary-gray-70;
  }
}

body {
  overflow: hidden;
}

/** SUBSIDEBAR **/
.sub-sidebar {
  width: 190px;

  &__divider {
    border-top-width: 1px;
    border-top-style: dashed;
    border-top-color: $secondary-gray-80;
  }
}

/** SUBSUBSIDEBAR **/
.sub-sub-sidebar {
  width: 280px;

  &__divider {
    border-top-width: 1px;
    border-top-style: dashed;
    border-top-color: $secondary-gray-80;
  }

  @media (max-width: 767px) {
    margin-left: -204px;
    width: 240px;
  }
}

/** MAT-CHECKBOX FIELD **/
.form-fields-checkbox-container {
  color: $secondary-gray-20;

  &__label {
    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__content {
    &::ng-deep {

      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-mdc-form-field-infix {
        border-top: 0rem solid transparent;
        padding-bottom: 0.8rem;
        padding-top: 1.15rem;
      }

      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick .mat-mdc-form-field-outline-start,
      .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick .mat-mdc-form-field-outline-end,
      .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick .mat-mdc-form-field-outline-gap {
        border-width: 1px;
      }
    }

    .mat-mdc-checkbox .mat-mdc-checkbox-ripple,
    .mdc-checkbox__ripple {
      display: none;
    }

    .mdc-checkbox__native-control:focus:focus:not(:checked)~.mdc-checkbox__background,
    .mdc-checkbox__native-control:focus:focus:not(:indeterminate)~.mdc-checkbox__background {
      border-color: #736d70;
    }

    .mat-internal-form-field>label {
      margin-left: 0;
      margin-right: auto;
      padding-left: 0px;
      padding-right: 0;
      order: 0;
      white-space: nowrap;
    }

    .mat-mdc-checkbox .mat-mdc-checkbox-background,
    .mdc-checkbox__background {
      border: 1px solid currentColor;
      border-radius: 3px;
      border-color: #736d70;
      background-color: #322e33;
      width: 16px;
      height: 16px;
    }

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
      border-color: #33ffa2;
      background-color: #322e33;
    }


    .mdc-checkbox__native-control:focus:focus:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:focus:focus:indeterminate~.mdc-checkbox__background {
      border-color: #33ffa2;
      background-color: #322e33;
    }

    .mdc-checkbox:hover .mdc-checkbox__native-control:not(:checked)~.mdc-checkbox__background,
    .mdc-checkbox:hover .mdc-checkbox__native-control:not(:indeterminate)~.mdc-checkbox__background {
      border-color: #969699;
      background-color: #322e33;
    }


    .mdc-checkbox:hover .mdc-checkbox__native-control:checked~.mdc-checkbox__background,
    .mdc-checkbox:hover .mdc-checkbox__native-control:indeterminate~.mdc-checkbox__background {
      border-color: #33ffa2;
      background-color: #322e33;
    }

    .mat-mdc-checkbox-layout.mat-accent {

      /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-mdc-checkbox-layout {
        cursor: pointer;
        font-family: $font-family-medium;

        /** GENERAL **/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-mdc-checkbox-inner-container {
          background-color: $primary-dark-purple;
          margin-right: 0.75rem;

          /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
          .mat-mdc-checkbox-frame {
            border: 1px solid $secondary-gray-70;
            box-sizing: border-box;
            border-radius: 3px;
          }

          /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
          .mat-mdc-checkbox-ripple {
            display: none;
          }
        }

        /** FOCUS **/
        // &:focus {
        //   .mat-checkbox-inner-container {
        //     background-color: $primary-dark-purple;
        //     .mat-checkbox-frame {
        //       border: 1px solid #6e94c7;
        //       box-shadow: 0px 0px 10px rgba(110, 148, 199, 0.5);
        //     }
        //   }
        // }

        /** HOVER **/
        &:hover {

          /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
          /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
          .mat-mdc-checkbox-inner-container {
            background-color: $primary-dark-purple;

            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-mdc-checkbox-frame {
              border: 1px solid $secondary-gray-60;
            }
          }
        }
      }

      /** INVALID **/
      /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      &.invalid .mat-mdc-checkbox-layout .mat-mdc-checkbox-inner-container {

        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-mdc-checkbox-frame {
          border: 1px solid $status-error;
        }

        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-mdc-checkbox-background {
          background-color: transparent;
        }
      }

      /** CHECKED **/
      /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      &.mat-mdc-checkbox-checked .mat-mdc-checkbox-layout .mat-mdc-checkbox-inner-container {

        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-mdc-checkbox-frame {
          border: 1px solid $primary-green-50;
        }

        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-mdc-checkbox-background {
          background-color: transparent;

          /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
          /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
          .mat-mdc-checkbox-checkmark {
            fill: $primary-green-50;

            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-mdc-checkbox-checkmark-path {
              stroke: $primary-green-50 !important;
            }
          }

          /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
          .mat-mdc-checkbox-mixedmark {
            background-color: $primary-green-50;
          }
        }

        &:hover {

          /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
          .mat-mdc-checkbox-frame {
            border: 1px solid $primary-green-50;
          }
        }
      }
    }
  }
}


/** MAT-RANGE FIELD **/
.form-fields-range-container {
  &__content {}
}

:root {
  @include mat.badge-overrides((background-color: #33ffa2,
      text-color: #2a262b,
      small-size-container-offset:-16px,
      //small-size-line-height:2rem,
       text-weight: 700,
       small-size-text-size:11px,

    ));
}

/** MAT-BADGE **/
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -7px;
  right: -7px;
  //padding: 0px;
  // font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  // font-weight: 700;
  // line-height: 20px;
  // color: #2a262b;
  // background: #33ffa2;
  //border-radius: 100%;
}

/** CALENDAR **/
.mat-calendar-abbr {
  text-decoration: none;
}

.mat-calendar-abbr {
  text-decoration: none;
}

.mat-mdc-paginator {
  background-color: transparent;
}

.mat-mdc-paginator-page-size {
  display: none !important;
}

/** MAT DIALOG **/

// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
  @include mat.dialog-overrides((container-color: $primary-no-black,
      container-shape: 3px,
      headline-padding: 20px,
      container-max-width: 2200px));
}

/*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
// mat-mdc-dialog-container.mat-mdc-dialog-container {
//   padding: 1.143rem 1.714rem 1.714rem;
//   background: $primary-no-black;
//   color: $primary-white;

//   overflow: hidden;
//   max-height: 100vh;

//   border: 1px solid $secondary-gray-80;
//   box-sizing: border-box;

//   box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1),
//     0px 8px 16px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
//     0px 2px 4px rgba(0, 0, 0, 0.1);
//   border-radius: 3px;
// }

.mat-mdc-dialog-container .mat-mdc-dialog-content {
  --mat-dialog-content-padding: 0px 24px; /* Top/Bottom auf 0px, Left/Right auf 24px */
  max-height: 65vh;
}


:root .mat-mdc-dialog-inner-container {}

:root .mat-mdc-dialog-surface {
  padding: 1.143rem 0rem 1.714rem 0rem;
  border: 1px solid $secondary-gray-80;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1),
    0px 8px 16px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 2px 4px rgba(0, 0, 0, 0.1);
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field-infix {
  height: 40px;
}

/** FORM STYLING **/

.sound-form {
  background: #2a262b;
  border: 1px solid #322e33;
  box-sizing: border-box;
  border-radius: 3px;

  padding: 5.714rem
    /* 91.424px */
  ; // p-20;

  &__group {
    margin-bottom: 2.286rem
      /* 36.576px */
    ; // mb-8;

    h3 {
      color: #73F8BB;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 1.714rem;
    }
  }

  &__actions {
    margin-top: 4.571rem
      /* 73.136px */
    ; // mt-16
    place-content: space-between;
  }

  &__note {
    margin-top: 4.571rem
      /* 73.136px */
    ; // mt-16;
  }
}

html.cdk-global-scrollblock {
  overflow: hidden;
  max-height: 100vh;
}

.white-text {
  color: white !important;
}

/* Für das Floating-Label */
.mat-mdc-floating-label {
  font-size: 14px !important; /* Schriftgröße anpassen */
  transform: translateY(-50px) !important; /* Label nach oben verschieben */
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px !important; /* Neue Schriftgröße setzen */
}



/* Textgröße für die Menüitems ändern */
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-size: 14px !important; /* Ändere die Schriftgröße nach Bedarf */
  font-family: 'Montserrat-Medium', sans-serif !important;
}

.mat-mdc-menu-panel {
  outline: 1;
  border-radius: 2px;
  background-color: #322E33 !important; /* Beispiel: Rot als Hintergrundfarbe */
}

// Anpassung der Tab-Hintergrundfarbe und Schriftgröße
.mat-mdc-tab {
  background-color: #322E33 !important; /* Hintergrundfarbe */
  font-size: 16px !important; /* Schriftgröße */
  border-radius: 4px !important; /* Abrunden der Ecken für den aktiven Tab */

}

// Textfarbe für den Tab-Text ändern
.mat-mdc-tab .mdc-tab__text-label {
  color: #969699 !important; /* Textfarbe */
}

// Anpassung für den aktiven Tab
.mat-mdc-tab.mdc-tab--active {
  background-color: #33FFA2 !important; /* Andere Hintergrundfarbe für aktiven Tab */
  border-radius: 4px !important; /* Abrunden der Ecken für den aktiven Tab */
}
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #322E33 !important; /* Textfarbe für den aktiven Tab */
}

.mat-mdc-tab-header {

  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-mdc-tab-label-content {
    color: white;
  }

  .mat-ink-bar {}
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: white;
}

:root {
  @include mat.tabs-overrides((
    active-indicator-color: white,
    active-focus-indicator-color: white,
    active-hover-indicator-color: white,
  ));
}

.waveform-wavesurfer ::part(cursor) {
  display: none;
}

#waveformsimilarity-search ::part(rangeSelector) {
  border: 1px solid #D7FF73;
  border-radius: 3px;
}

#waveformsimilarity-search ::part(region) {
  background-color: rgba(133, 51, 166, 0.3) !important;
}

::ng-deep .wavesurfer-region>.wavesurfer-handle {
  background-color: transparent !important;
}

#waveformsimilarity-search ::part(region-handle-left) {
  border-left: 8px solid rgba(215, 255, 115, 1.0);
}

#waveformsimilarity-search ::part(region-handle-left):after {
  content: "";
  position: absolute;
  top: calc(50% - 3px);
  left: -6px;
  width: 5px;
  height: 5px;
  border-top: 1px solid #2A262B;
  border-left: 1px solid #2A262B;
  transform: rotate(-45deg);
}

#waveformsimilarity-search ::part(region-handle-right) {
  width: 40px;
  padding-top: 56px;
  border-right: 8px solid rgba(215, 255, 115, 1.0);
}

#waveformsimilarity-search ::part(region-handle-right):after {
  content: "";
  position: absolute;
  top: 36px;
  right: -6px;
  width: 5px;
  height: 5px;
  border-bottom: 1px solid #32212e;
  border-right: 1px solid #32212e;
  transform: rotate(-45deg);
}

#waveformsimilarity-search ::part(region-handle-left) .handle-start__time,
#waveformsimilarity-search ::part(region-handle-right) .handle-end__time {
  position: absolute;
  word-break: keep-all;
  color: #D7FF73;
  line-height: 1;
  top: calc(50% - 7px);
}

#waveformsimilarity-search ::part(region-handle-left) .handle-start__time {
  left: -44px;
}

#waveformsimilarity-search ::part(region-handle-left) .handle-end__time {
  left: 28px;
}

// SAFARI GAP HANDLING
@supports (-webkit-touch-callout: none) {
  .gap-2.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 0.5rem;
  }

  .gap-3.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 0.75rem;
  }

  .gap-4.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 1rem;
  }

  .gap-5.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 1.25rem;
  }

  .gap-6.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 1.5rem;
  }

  .gap-7.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 1.75rem;
  }

  .gap-8.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 2rem;
  }

  .gap-9.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 2.25rem;
  }

  .gap-10.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 2.5rem;
  }

  .gap-11.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 2.75rem;
  }

  .gap-12.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 3rem;
  }

  .gap-3.flex-col>*:not(:last-child),
  .gap-3.flex-wrap>*:not(:last-child),
  .gap-3:not(.flex)>*:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  .gap-4.flex-col>*:not(:last-child),
  .gap-4.flex-wrap>*:not(:last-child),
  .gap-4:not(.flex)>*:not(:last-child) {
    margin-bottom: 1rem;
  }

  .gap-5.flex-col>*:not(:last-child),
  .gap-5.flex-wrap>*:not(:last-child),
  .gap-5:not(.flex)>*:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .gap-6.flex-col>*:not(:last-child),
  .gap-6.flex-wrap>*:not(:last-child),
  .gap-6:not(.flex)>*:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .gap-7.flex-col>*:not(:last-child),
  .gap-7.flex-wrap>*:not(:last-child),
  .gap-7:not(.flex)>*:not(:last-child) {
    margin-bottom: 1.75rem;
  }

  .gap-8.flex-col>*:not(:last-child),
  .gap-8.flex-wrap>*:not(:last-child),
  .gap-8:not(.flex)>*:not(:last-child) {
    margin-bottom: 2rem;
  }

  .gap-9.flex-col>*:not(:last-child),
  .gap-9.flex-wrap>*:not(:last-child),
  .gap-9:not(.flex)>*:not(:last-child) {
    margin-bottom: 2.25rem;
  }

  .gap-10.flex-col>*:not(:last-child),
  .gap-10.flex-wrap>*:not(:last-child),
  .gap-10:not(.flex)>*:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .gap-11.flex-col>*:not(:last-child),
  .gap-11.flex-wrap>*:not(:last-child),
  .gap-11:not(.flex)>*:not(:last-child) {
    margin-bottom: 2.75rem;
  }

  .gap-12.flex-col>*:not(:last-child),
  .gap-12.flex-wrap>*:not(:last-child),
  .gap-12:not(.flex)>*:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.mobile-hidden {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.mobile-visible {
  display: none !important;

  @media (max-width: 767px) {
    display: block !important;
  }
}

.mat-horizontal-stepper-header-container {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.mat-mdc-menu-item {
  font-family: 'Montserrat-Medium', sans-serif !important;
  font-weight: 500;
}

.mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;

  span {
    font-size: 14px;
    font-family: 'Montserrat-Medium', sans-serif !important;
    font-weight: 500;
    flex: 1 1 auto;
  }
}

.mat-mdc-form-field-infix {
  min-height: 40px !important;
}

.mat-mdc-form-field-error {
  color: #e6b8d0 !important;
  font-family: Montserrat-Medium;
  font-style: normal;
  font-size: .7857rem;
  line-height: 1.1428rem;
}

.mat-mdc-text-field-wrapper {
  border: 0px solid #3e3a40 !important;
  background: #322e33 !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
  margin-top: 0px;
}

.mat-mdc-notch-piece {
  //border-color: #3e3a40 !important;
  border: 0px;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
  border-color: #3e3a40 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):hover .mat-mdc-notch-piece {
  border-color: #858585 !important;
}

.mdc-text-field--outlined.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mat-mdc-notch-piece {
  border-color: #d9ffee !important;
  border-width: 1px !important;
}


.mat-mdc-input-element,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
  font-family: Montserrat-Medium !important;
  color: #e5e5ea !important;
  caret-color: #fff !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 32px !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

/* Error-Zustand (invalid) */
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
  border-color: #e6b8d0 !important;
  border-width: 1px !important;
}

/* Standard Syntax */
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: #736d70 !important;
}

.mat-mdc-form-field-infix {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

:root {
  .mat-mdc-tooltip-surface {
    overflow: visible;
  }

  @include mat.tooltip-overrides((container-color: #d7ff73,
      container-shape: 2px,
      supporting-text-tracking: -0.015em,
      supporting-text-color: #000000,
      supporting-text-font: Montserrat-Medium,
      supporting-text-size: 13px,
      supporting-text-weight: 500));
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 200px;
}

:root {
  @include mat.button-overrides((
    filled-container-color: orange,
    filled-label-text-color: red,
  ));
}